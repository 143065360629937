<script setup>
import MainLayout from "../components/MainLayout.vue";

// Page Title of Views
const pageTitle = "About";
</script>

<template>
  <MainLayout :pageTitle="pageTitle">
    <section>
      <div class="aboutme flex flex-col lg:flex-row justify-between p-12 mb-8">
        <div class="flex flex-col gap-3 p-0 lg:p-12 md:p-0 sm:p-8">
          <h1 id="aboutMe" class="font-bold text-3xl uppercase text-indigo-800 mb-12">
            About Me
          </h1>
          <h1 class="font-bold text-7xl">
            Better design, <br />
            better experience
          </h1>
          <p class="font-normal text-2xl text-gray-500 mt-2">
            I weave code and design into a website, nurturing its development
            with a passion that transcends pixels, creating an online realm
            where love finds its unique expression.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="bg-gray-100 py-6 sm:py-8 lg:py-12 p-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <!-- text - start -->
          <div class="mb-10 md:mb-16">
            <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 sm:text-md lg:text-4xl">
              My Skills
            </h2>
          </div>
          <!-- text - end -->

          <div class="grid gap-8 sm:grid-cols-2 md:gap-12 xl:grid-cols-3 xl:gap-16">
            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img
                  src="https://res.cloudinary.com/thewebmaster/image/upload/c_scale,f_auto,q_auto:best,w_516/images/html/html5.png"
                  alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">Html</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  As a passionate web developer, I craft digital experiences
                  that seamlessly blend creativity and functionality.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img src="https://seeklogo.com/images/V/vuejs-logo-17D586B587-seeklogo.com.png" class="h-8 mt-1" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">VueJS</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  I coordinate interactive online interfaces, balancing adaptability and responsiveness to create a
                  compelling and contemporary user experience in virtual spaces.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/600px-JavaScript-logo.png?20120221235433"
                  class="h-10" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">
                  Javascript
                </h3>
                <p class="mb-2 text-gray-500 text-sm">
                  In JavaScript, I manipulate data and create dynamic web
                  features for interactive and responsive websites.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/600px-Tailwind_CSS_Logo.svg.png?20211001194333"
                  class="h-7" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">Tailwind</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  Leveraging Tailwind CSS, I craft sleek web interfaces with
                  concise utility classes, ensuring seamless and responsive
                  designs.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/121px-Laravel.svg.png"
                  class="h-11" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">Laravel</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  In Laravel's realm, I construct dynamic web systems, molding
                  backend intricacies to forge seamless, secure, and efficient
                  experiences.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img src="https://seeklogo.com/images/B/bootstrap-logo-3C30FB2A16-seeklogo.com.png" class="h-10" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">Bootstrap</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  I use Bootstrap to create efficient and visually appealing web
                  designs with responsive interfaces for seamless user
                  experiences.
                </p>
              </div>
            </div>
            <!-- feature - end -->
            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img src="https://cdn-icons-png.flaticon.com/512/5968/5968332.png" class="h-10" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">PHP</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  With expertise in PHP, I skillfully develop dynamic and robust
                  web applications, ensuring efficient server-side functionality
                  and optimal user interactions.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img
                  src="https://www.freepnglogos.com/uploads/logo-mysql-png/logo-mysql-mysql-logo-png-images-are-download-crazypng-21.png"
                  class="h-10" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">MySQL</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  My proficiency in MySQL extends to expertly designing
                  databases, ensuring efficient data management for powerful and
                  reliable web applications.
                </p>
              </div>
            </div>
            <!-- feature - end -->

            <!-- feature - start -->
            <div class="flex gap-4 md:gap-6">
              <div
                class="flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-white text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <img
                  src="https://cdn.sanity.io/images/599r6htc/localized/46a76c802176eb17b04e12108de7e7e0f3736dc6-1024x1024.png?w=670&h=670&q=75&fit=max&auto=format"
                  class="h-10" alt="" />
              </div>

              <div>
                <h3 class="mb-2 text-lg font-semibold md:text-xl">Figma</h3>
                <p class="mb-2 text-gray-500 text-sm">
                  I creatively design user interfaces, blending aesthetics and
                  functionality for visually compelling and user-centric web
                  experiences.
                </p>
              </div>
            </div>
            <!-- feature - end -->
          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</template>
