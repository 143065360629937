<template>
  <footer class="bg-indigo-950 text-white rounded">
    <div class="pb-16 pt-4 sm:pt-10 lg:pt-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div
          class="grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-5 lg:gap-8 lg:pt-12"
        >
          <div class="col-span-full lg:col-span-2">
            <div class="mb-4 lg:-mt-2">
              <h1 class="text-7xl font-bold mb-3">Let's Talk!</h1>
              <h1 class="text-2xl font-semibold">Contact Info</h1>
              <p>dev.zhenllyalvarez@gmail.com</p>
              <p>Deca Homes Mactan 1 Lapu-Lapu City</p>
              <p>+63 907 907 7532</p>
            </div>
          </div>

          <!-- nav - start -->
          <div>
            <div class="mb-4 text-2xl font-bold mb-5 uppercase tracking-widest">
              Service
            </div>

            <nav class="flex flex-col gap-4">
              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Web Design</a
                >
              </div>

              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Web Development</a
                >
              </div>

              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >UI/UX Design</a
                >
              </div>
            </nav>
          </div>
          <!-- nav - end -->

          <!-- nav - start -->
          <div>
            <div class="mb-4 uppercase tracking-widest text-2xl font-bold mb-5">
              Products
            </div>

            <nav class="flex flex-col gap-4">
              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Theme Design</a
                >
              </div>

              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Plugins Design</a
                >
              </div>

              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Wordpress</a
                >
              </div>

              <div>
                <a
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Html Template</a
                >
              </div>
            </nav>
          </div>
          <!-- nav - end -->

          <!-- nav - start -->
          <div>
            <div class="mb-4 text-2xl font-bold mb-5 uppercase tracking-widest">
              Quick Links
            </div>

            <nav class="flex flex-col gap-4">
              <div>
                <a href="/"
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Home</a
                >
              </div>

              <div>
                <a href="/about"
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >About</a
                >
              </div>

              <div>
                <a href="/projects"
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Project</a
                >
              </div>

              <div>
                <a href="/contact"
                  class="transition duration-100 hover:text-indigo-500 active:text-indigo-600 cursor-pointer"
                  >Contact</a
                >
              </div>
            </nav>
          </div>
          <!-- nav - end -->
        </div>
      </div>
    </div>

    <div class="bg-indigo-950">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="flex items-center justify-between gap-4 py-8">
          <span class="text-sm text-gray-400">
            © 2023 - John Zhenlly Alvarez. All rights reserved.
          </span>

          <!-- social - start -->
          <div class="flex gap-4">
            <a
              href="https://www.facebook.com/john.zhenlly.56"
              target="_blank"
              class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600"
            >
              <svg
                class="h-5 w-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C5.373 0 0 5.373 0 12c0 5.976 4.36 10.943 10 11.868V15.65H7V12h3v-2.614c0-3.463 2.056-5.386 5.238-5.386 1.514 0 3.01.269 3.01.269v3h-1.695c-1.67 0-2.195 1.04-2.195 2.113V12h3.734l-.598 3.65h-3.136v8.217C19.64 22.306 24 17.337 24 12c0-6.627-5.373-12-12-12"
                />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/in/john-zhenlly-alvarez-73021028b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app&fbclid=IwAR3oxwIaLXbYXSXn_QdAHgMnCeEZh4w0Da3PyZxAEHCLDLklUwyKbW9MvYk"
              target="_blank"
              class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600"
            >
              <svg
                class="h-5 w-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                />
              </svg>
            </a>

            <a
              href="https://github.com/zhenllyalvarez"
              target="_blank"
              class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600"
            >
              <svg
                class="h-5 w-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
                />
              </svg>
            </a>
          </div>
          <!-- social - end -->
        </div>
      </div>
    </div>
  </footer>
</template>
